import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import {
  getObjectiveLinksFromAPI,
  getObjectiveWithIdFromAPI,
  getTeamObjectivesFromAPI,
  onCompanyObjectiveCreated,
  onKeyresultCreated,
  onKeyResultUpdated,
  onPersonObjectiveCreated,
  onTeamObjectiveCreated,
  submitCompanyObjectiveToAPI,
  submitDeleteObjectiveToAPI,
  submitEditedKeyResultToAPI,
  submitNewKeyResultToAPI,
  submitPersonObjectiveToAPI,
  submitTeamObjectiveToAPI,
  submitUpdatedKeyResultToAPI,
  submitUpdatedObjectiveToAPI,
} from 'state/ducks/objectives/logic-handlers';
import { API_OBJECTIVES_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';

import * as selectors from './selectors';
import { get } from 'lodash';

const SLICE_NAME = 'objectives';

export const deleteObjectiveLogic = createLogic({
  type: types.DELETE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    submitDeleteObjectiveToAPI(state.auth.tokens.access_token, state.auth.tenantID, action.payload)
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveDeleted(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const getPeriodsLogic = createLogic({
  type: types.GET_PERIODS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectPeriodConfig(state.main[SLICE_NAME]),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .get(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/getperiods`, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.periodsReceived(r));
      })
      .catch(e => {
        dispatch(actions.periodFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const getHierarchyLogic = createLogic({
  type: types.GET_HIERARCHY,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectHierarchy(state.main[SLICE_NAME], action.payload.stperiod),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .get(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/getobjectivehierarchy`, {
        params: { stperiod: action.payload.stperiod },
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.hierarchyReceived(r));
      })
      .catch(e => {
        dispatch(actions.hierarchyFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const getObjectiveLinksLogic = createLogic({
  type: types.GET_OBJECTIVE_LINKS,
  debounce: 2000,

  validate({ getState, action }, allow, reject) {
    const state = getState();

    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectRelations(
          state.main[SLICE_NAME],
          action.payload.objectiveID,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    getObjectiveLinksFromAPI(state.auth.tokens.access_token, state.auth.tenantID, action.payload)
      .then(res => {
        dispatch(actions.objectiveLinksReceived(res.data.result));
      })
      .catch(e => {
        dispatch(actions.objectiveLinksFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const createPersonObjectiveLogic = createLogic({
  type: types.CREATE_PERSON_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitPersonObjectiveToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        onPersonObjectiveCreated(res, state, action, dispatch);
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

/* COMPANY */
export const createCompanyObjectiveLogic = createLogic({
  type: types.CREATE_COMPANY_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitCompanyObjectiveToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        onCompanyObjectiveCreated(res, state, dispatch);
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const getCompanyObjectivesLogic = createLogic({
  type: types.GET_COMPANY_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectCompanyObjectives(
          state.main[SLICE_NAME],
          action.payload && action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .get(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/getcompanyobjectives`, {
        params: { stperiod: action.payload.stperiod },
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.companyObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(actions.companyObjectivesFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const getRelatedObjectivesLogic = createLogic({
  type: types.GET_RELATED_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectRelatedObjectives(
          state.main[SLICE_NAME],
          action.payload.sub,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .get(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/getrelatedobjectives`, {
        params: { stperiod: action.payload.stperiod, sub: action.payload.sub },
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.relatedObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(actions.relatedObjectivesFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const getTeamObjectivesLogic = createLogic({
  type: types.GET_TEAM_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      action.payload.teamId &&
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectTeamObjectives(
          state.main[SLICE_NAME],
          action.payload.teamId,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
      // reject();
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    getTeamObjectivesFromAPI(state.auth.tokens.access_token, state.auth.tenantID, action.payload)
      .then(res => {
        const r = res.data.result;
        dispatch(actions.teamObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(
          actions.teamObjectivesFailed({ request: { teamId: action.payload.teamId }, response: e }),
        );
      })
      .then(() => done());
  },
});

export const createTeamObjectiveLogic = createLogic({
  type: types.CREATE_TEAM_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitTeamObjectiveToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        onTeamObjectiveCreated(res, state, action, dispatch);
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const createKeyresultLogic = createLogic({
  type: types.CREATE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitNewKeyResultToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        onKeyresultCreated(res, state, dispatch);
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const updateKeyresultLogic = createLogic({
  type: types.UPDATE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitUpdatedKeyResultToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        onKeyResultUpdated(res, state, action.payload, dispatch);
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const updateKeyresultTodosLogic = createLogic({
  type: types.UPDATE_KEYRESULT_TODOS,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/updatekeyresulttodos`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const result = { ...res.data.result, sub: state.auth.userID };
        dispatch(actions.keyresultUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editKeyresultLogic = createLogic({
  type: types.EDIT_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitEditedKeyResultToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        const result = { ...res.data.result, sub: state.auth.userID };
        dispatch(actions.keyresultUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteKeyresultLogic = createLogic({
  type: types.DELETE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/deletekeyresult`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.keyresultDeleted(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const updateObjectiveLogic = createLogic({
  type: types.UPDATE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const pl = { ...action.payload };
    if (pl.description_rt) {
      pl.description_rt = JSON.stringify(pl.description_rt);
    }
    submitUpdatedObjectiveToAPI(state.auth.tokens.access_token, state.auth.tenantID, pl)
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveUpdated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

export const gradeObjectiveLogic = createLogic({
  type: types.GRADE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/gradeobjective`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveGraded(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const updateObjectiveParentLogic = createLogic({
  type: types.UPDATE_OBJECTIVE_PARENT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/editobjectiveparent`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveParentUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const getObjectiveLogic = createLogic({
  type: types.GET_OBJECTIVE,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    // Filter out objectives that have already been fetched
    const allowedRngs = [];
    for (const rng of action.payload.objectiveIDs) {
      if (
        selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
        shouldFetch(
          selectors.selectObjective(state.main[SLICE_NAME], rng),
          state.main.connection,
          !!action.payload && action.payload.force,
        )
      ) {
        allowedRngs.push(rng);
      }
    }
    if (allowedRngs.length > 0) {
      action.payload.objectiveIDs = allowedRngs;
      allow(action);
    } else {
      reject();
    }
  },
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    getObjectiveWithIdFromAPI(state.auth.tokens.access_token, state.auth.tenantID, {
      objectiveIDs: JSON.stringify(action.payload.objectiveIDs),
    })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveReceived(result));
      })
      .catch(e => {
        dispatch(actions.objectiveFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const copyToPeriodLogic = createLogic({
  type: types.COPY_TO_PERIOD,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/copyobjectivetoperiod`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(
          actions.objectiveCopiedToPeriod({
            ...result,
            sourceID: action.payload.objectiveID,
            targetStPeriod: action.payload.stperiod,
            targetLtPeriod: action.payload.ltperiod,
          }),
        );
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const sortDomainOkrsLogic = createLogic({
  type: types.SORT_DOMAIN_OKRS,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    // As we're doing optimistic updates, we'll need to prepare and store
    // a revert payload to be used in case the API returns an error
    const revertPayload = {
      updates: [],
    };
    action.payload.updates.forEach(updatePayload => {
      const { objectiveID } = updatePayload;
      const domainId = Object.keys(updatePayload.positions)[0];
      const periodId = Object.keys(updatePayload.positions[domainId])[0];

      revertPayload.updates.push({
        objectiveID: updatePayload.objectiveID,
        positions: {
          [domainId]: {
            [periodId]: get(state.main[SLICE_NAME].objectiveData[objectiveID].data.positions, [
              domainId,
              periodId,
            ]),
          },
        },
      });
    });

    // Optimistic update
    dispatch(actions.sortedDomainOkrs(action.payload));

    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/editobjectives`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        // all is good
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        // an error was returned, we'll have to assume the sorting failed
        // revert to prior positions
        dispatch(actions.sortFailedDomainOKrs(revertPayload));
      })
      .then(() => done());
  },
});

export const sortKeyresultsLogic = createLogic({
  type: types.SORT_KEYRESULTS,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { objectiveID } = action.payload;
    // As we're doing optimistic updates, we'll need to prepare and store
    // a revert payload to be used in case the API returns an error
    const revertPayload = {
      objectiveID: objectiveID,
      updates: [],
    };
    const apiPayload = {
      updates: [],
    };
    action.payload.updates.forEach(updatePayload => {
      const { keyresultID, position } = updatePayload;
      const krData = state.main[SLICE_NAME].objectiveData[objectiveID].data.keyresults.filter(
        krData => krData.keyresultID === keyresultID,
      )[0];

      apiPayload.updates.push({
        keyresultID: keyresultID,
        position: position,
      });
      revertPayload.updates.push({
        keyresultID: keyresultID,
        position: krData.position,
      });
    });

    // Optimistic update
    dispatch(actions.sortedKeyresults(action.payload));

    axios
      .post(`${API_OBJECTIVES_URL}/${state.auth.tenantID}/editkeyresults`, apiPayload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        // all is good
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        // an error was returned, we'll have to assume the sorting failed
        // revert to prior positions
        dispatch(actions.sortFailedKeyresults(revertPayload));
      })
      .then(() => done());
  },
});
