import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  HeadingExtension,
  LinkExtension,
  UnderlineExtension,
  OrderedListExtension,
  TaskListExtension,
  BoldExtension,
  ItalicExtension,
  TrailingNodeExtension,
  BulletListExtension,
  PlaceholderExtension,
} from 'remirror/extensions';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import Box from '@mui/material/Box';
import { EditLinkDialog } from 'Components/Library/RichTextEditor/Extensions/EditLinkDialog/EditLinkDialog';
// eslint-disable-next-line import/extensions
import { TgMentionAtomExtension } from './Extensions/Mentions/TgMentionAtomExtension.ts';
import TgMentionComponent from './Extensions/Mentions/TgMentionComponent';

import Toolbar from './Toolbar';
import ValidatorHandle from './ValidatorHandle';
import getEditorStyles, { DEFAULT_FONT_VARIANT_MAPPING } from './styles';
import { SCHEMA_FULL } from './schemas';

const extraAttributes = [
  { identifiers: ['mention'], attributes: { role: { default: 'presentation' } } },
  { identifiers: ['mention'], attributes: { href: { default: null } } },
];

function RichTextEditor(props) {
  const {
    value,
    onChange,
    handleRef,
    submitButton,
    error,
    toolbarVariant,
    placeholder,
    label,
    schema,
    variant,
    minRows,
    fontVariantOverrides = {},
    stringHandler = 'text',
  } = props;

  const [isEditingLink, setIsEditingLink] = useState(false);

  const extensions = useCallback(() => {
    const extensionList = [
      new PlaceholderExtension({ placeholder }),
      new TgMentionAtomExtension({
        matchers: [{ name: 'at', char: '@', appendText: ' ' }],
      }),
      new LinkExtension({ autoLink: true, selectTextOnClick: true }),
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new OrderedListExtension(),
      new TaskListExtension(),
      new BulletListExtension(),
      new TrailingNodeExtension(),
    ];
    if (schema === SCHEMA_FULL) {
      extensionList.push(new HeadingExtension({}));
    }
    return extensionList;
  }, []);

  const onError = useCallback(
    ({ json, invalidContent, transformers }) => transformers.remove(json, invalidContent),
    [],
  );

  const fontVariantMap = { ...DEFAULT_FONT_VARIANT_MAPPING, ...fontVariantOverrides };

  const { manager } = useRemirror({
    extensions,
    extraAttributes,
    onError,
    stringHandler,
  });

  return (
    <Box
      className={clsx({ 'editor-error-state': error })}
      sx={getEditorStyles(variant, minRows, fontVariantMap)}
    >
      <AllStyledComponent>
        <Remirror
          manager={manager}
          label={label}
          onChange={parameter => {
            onChange(parameter);
          }}
          initialContent={value}
        >
          <ValidatorHandle ref={handleRef} showError={error} />
          <EditorComponent />
          <TgMentionComponent />
          <Toolbar
            submitButton={submitButton}
            variant={toolbarVariant}
            schema={schema}
            fontVariantMap={fontVariantMap}
            setIsEditingLink={setIsEditingLink}
            isEditingLink={isEditingLink}
          />
          <EditLinkDialog
            setIsEditingLink={setIsEditingLink}
            isEditingLink={isEditingLink}
            isFloating={toolbarVariant === 'floating'}
          />
        </Remirror>
      </AllStyledComponent>
    </Box>
  );
}

RichTextEditor.propTypes = {
  toolbarVariant: PropTypes.oneOf(['bottom', 'floating']),
  schema: PropTypes.oneOf(['comment', 'all']),
  variant: PropTypes.oneOf(['inline', 'filled', 'standard']),
  minRows: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func,
  handleRef: PropTypes.object,
  submitButton: PropTypes.node,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  fontVariantOverrides: PropTypes.object,
  stringHandler: PropTypes.string,
};

RichTextEditor.defaultProps = {
  submitButton: null,
  schema: 'all',
  variant: 'inline',
  minRows: 2,
};

export default RichTextEditor;
