import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import ObjectivesWidgetCard from './ObjectivesWidgetCard';
import ObjectivesWidgetExpanded from './ObjectivesWidgetExpanded';

function ObjectivesWidget({ variant, canEdit, teamId, dragHandleProps, requestRemove, name }) {
  const currentPeriod = useSelector(state =>
    objectivesSelectors.selectCurrentPeriod(state.main.objectives),
  );
  const [period, selectPeriod] = useState();
  const appliedPeriod = period || currentPeriod;

  const nextShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectNextShortTermPeriod(state.main.objectives, appliedPeriod),
  );
  const prevShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectPrevShortTermPeriod(state.main.objectives, appliedPeriod),
  );

  if (variant === 'card') {
    return (
      <ObjectivesWidgetCard
        key={`okr-widget-${teamId}-appliedPeriod`}
        teamId={teamId}
        canEdit={canEdit}
        nextShortTermPeriod={nextShortTermPeriod}
        prevShortTermPeriod={prevShortTermPeriod}
        appliedPeriod={appliedPeriod}
        selectPeriod={selectPeriod}
        requestRemove={requestRemove}
        dragHandleProps={dragHandleProps}
        name={name}
      />
    );
  }
  return (
    <ObjectivesWidgetExpanded
      teamId={teamId}
      canEdit={canEdit}
      nextShortTermPeriod={nextShortTermPeriod}
      prevShortTermPeriod={prevShortTermPeriod}
      appliedPeriod={appliedPeriod}
      selectPeriod={selectPeriod}
      requestRemove={requestRemove}
      dragHandleProps={dragHandleProps}
      name={name}
    />
  );
}

ObjectivesWidget.propTypes = {
  teamId: PropTypes.string,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

ObjectivesWidget.defaultProps = {
  variant: 'card',
};

export default ObjectivesWidget;
