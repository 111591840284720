import React from 'react';
import PropTypes from 'prop-types';
import { FloatingWrapper } from '@remirror/react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import LinkButton from 'Components/Library/RichTextEditor/Buttons/LinkButton';
import BoldButton from './Buttons/BoldButton';
import ItalicButton from './Buttons/ItalicButton';
import UnderlineButton from './Buttons/UnderlineButton';
import OrderedListButton from './Buttons/OrderedListButton';
import UnorderedListButton from './Buttons/UnorderedListButton';
import TaskListButton from './Buttons/TaskListButton';
import HeadingsMenu from './Buttons/HeadingsMenu';

const STYLES = {
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'flex-end',
    p: 1,
  },
  floating: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 1,
    backgroundColor: 'background.box',
    boxShadow: 1,
    position: 'relative',
    left: '-8px',
  },
};

export default function Toolbar({
  submitButton,
  variant,
  schema,
  fontVariantMap,
  setIsEditingLink,
  isEditingLink,
}) {
  const content = (
    <Box sx={STYLES[variant]}>
      <ButtonGroup
        sx={{
          display: 'flex',
          flexDirection: 'row',
          '& .MuiButtonGroup-grouped': {
            border: 'none !important',
            borderRadius: '5px !important',
            minWidth: 32,
            height: 32,
            marginRight: '2px',
          },
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          overflow: 'clip',
        }}
      >
        {schema === 'all' && <HeadingsMenu fontVariantMap={fontVariantMap} />}
        <BoldButton />
        <ItalicButton />
        <UnderlineButton />
        <UnorderedListButton />
        <OrderedListButton />
        <TaskListButton />
        <LinkButton
          showEditLinkDialog={() => setIsEditingLink(true)}
          isEditingLink={isEditingLink}
        />
      </ButtonGroup>
      <Box sx={{ flexGrow: 20 }} />
      {submitButton}
    </Box>
  );
  if (variant === 'floating') {
    return (
      <FloatingWrapper positioner="selection" placement="top-start" renderOutsideEditor>
        {content}
      </FloatingWrapper>
    );
  }
  return content;
}

Toolbar.propTypes = {
  submitButton: PropTypes.node,
  variant: PropTypes.oneOf(['bottom', 'floating']),
  schema: PropTypes.oneOf(['comment', 'all']),
  fontVariantMap: PropTypes.object,
  setIsEditingLink: PropTypes.func,
  isEditingLink: PropTypes.bool,
};
